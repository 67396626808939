<template>
  <div class="aali_tm_section" id="contact">
    <div class="aali_tm_contact">
      <div class="container">
        <div
          class="aali_tm_main_title"
          data-text-align="center"
          data-color="dark"
        >
          <span>Get in Touch</span>
          <h3>Contact us</h3>
        </div>
        <div class="contact_inner">
          <div class="left wow fadeInLeft" data-wow-duration="1s">
            <div class="text">
              <p>
                Please fill out the form on this section to contact with us. Or
                call between 10:00 a.m. and 6:00 p.m., Sunday through Thursday
              </p>
            </div>
            <ul>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    `<span>Call me</span>
                  <h3>+965 60650544</h3>` + call
                  "
                ></div>
              </li>
              <li>
                <div
                  class="list_inner"
                  v-html="
                    msg +
                    `<span>Email</span>
                  <h3>
                    <a class='line_anim' href='mailto:hello@wusool.cc'
                      >hello@wusool.cc</a
                    >
                  </h3>`
                  "
                ></div>
              </li>
            </ul>
          </div>
          <div class="right wow fadeInRight" data-wow-duration="1s">
            <div class="fields">
              <form
                class="contact_form"
                id="contact_form"
                ref="form"
                @submit.prevent="handleSubmit"
              >
                <div
                  class="returnmessage"
                  data-success="Your message has been received, We will contact you soon."
                  :style="{
                    display: success ? 'block' : 'none',
                  }"
                >
                  <span class="contact_success">
                    Your message has been received, We will contact you soon.
                  </span>
                </div>
                <div
                  class="empty_notice"
                  :style="{ display: error ? 'block' : 'none' }"
                >
                  <span>Please Fill Required Fields</span>
                </div>
                <div class="input_list">
                  <ul>
                    <li>
                      <input
                        id="name"
                        type="text"
                        v-model="user_name"
                        name="user_name"
                        placeholder="Your Name"
                      />
                    </li>
                    <li>
                      <input
                        id="email"
                        type="text"
                        v-model="user_email"
                        name="user_email"
                        placeholder="Your Email"
                      />
                    </li>
                    <!-- <li>
                      <input
                        id="phone"
                        type="number"
                        placeholder="Your Phone"
                        name="phn"
                        v-model="fromData.phone"
                      />
                    </li> -->
                  </ul>
                </div>
                <div class="message_area">
                  <textarea
                    id="message"
                    name="message"
                    placeholder="Your message here"
                    v-model="message"
                  ></textarea>
                </div>
                <div class="aali_tm_button">
                  <a id="send_message" href="#" @click.prevent="handleSubmit"
                    ><span v-html="'Submit Message' + msgSent"> </span
                  ></a>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <span
        class="square moving_effect"
        data-direction="y"
        data-reverse="yes"
      ></span>
    </div>
  </div>
</template>

<script>
import { call, msg, msgSent } from "./svgImage";
import emailjs from '@emailjs/browser';

export default {
  name: "Contact",
  data() {
    return {
      msgSent,
      call,
      msg,
      user_name: "",
      user_email: "",
      message: "",
      error: false,
      success: false,
    };
  },
  methods: {
    handleSubmit() {
      if (this.user_name && this.user_email && this.message) {
        this.success = true;
        emailjs.sendForm('service_dnyhzm5', 'template_wukl2qe', this.$refs.form, '3PxiTKVWef9iamlf9')
        .then((result) => {
            console.log('SUCCESS!', result.text);
        }, (error) => {
            console.log('FAILED...', error.text);
        });
        setTimeout(() => {
          this.success = false;
        }, 2000);
      } else {
        this.error = true;
        setTimeout(() => {
          this.error = false;
        }, 2000);
      }
    },
  },
};
</script>
