var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_section"},[_c('div',{staticClass:"aali_tm_resume"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"resume_in"},[_vm._m(0),_c('div',{staticClass:"content_inner"},[_c('ul',[_c('li',{staticClass:"wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_vm._m(1),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(2),_vm._m(3)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(4),_vm._m(5)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.edu)}}),_vm._m(6),_vm._m(7)])])])]),_c('li',{staticClass:"wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_vm._m(8),_c('ul',{staticClass:"list"},[_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(9),_vm._m(10)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(11),_vm._m(12)])]),_c('li',[_c('div',{staticClass:"list_inner"},[_c('span',{staticClass:"icon",domProps:{"innerHTML":_vm._s(_vm.exp)}}),_vm._m(13),_vm._m(14)])])])])])])])])]),_vm._m(15)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_main_title",attrs:{"data-text-align":"left","data-color":"dark"}},[_c('span',[_vm._v("Know More")]),_c('h3',[_vm._v("My Resume")]),_c('p',[_vm._v(" I enjoy every step of the design process, from discussion and collaboration to concept and execution ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Education")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Cognitive Sciences")]),_c('span',[_vm._v("University of Maine")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2018 - 2020")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Civil Engineering")]),_c('span',[_vm._v("University of Texas")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2014 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Bachelor of Science")]),_c('span',[_vm._v("Univercity of Oxford")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2012 - 2014")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('h3',{staticClass:"main_title"},[_c('span',[_vm._v("Experience")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Product Designer")]),_c('span',[_vm._v("Easy Computers")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2018 - running")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("UI/UX Expert")]),_c('span',[_vm._v("eTheme Group")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2015 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"left"},[_c('h3',[_vm._v("Senior Developer")]),_c('span',[_vm._v("Envato Studio")])]),_c('div',{staticClass:"right"},[_c('span',[_vm._v("2012 - 2018")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Aali is a leading web design agency with an award-winning design ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media",attrs:{"data-background-style":"video"}},[_c('div',{staticClass:"video jarallax",attrs:{"data-speed":"0","data-jarallax-video":"https://vimeo.com/337293658"}}),_c('div',{staticClass:"image jarallax",attrs:{"data-speed":"0","data-img-url":"img/about/2.jpg"}}),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])
}]

export { render, staticRenderFns }