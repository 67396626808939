var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_section",attrs:{"id":"contact"}},[_c('div',{staticClass:"aali_tm_contact"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"contact_inner"},[_c('div',{staticClass:"left wow fadeInLeft",attrs:{"data-wow-duration":"1s"}},[_vm._m(1),_c('ul',[_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
                  `<span>Call me</span>
                <h3>+965 60650544</h3>` + _vm.call
                )}})]),_c('li',[_c('div',{staticClass:"list_inner",domProps:{"innerHTML":_vm._s(
                  _vm.msg +
                  `<span>Email</span>
                <h3>
                  <a class='line_anim' href='mailto:hello@wusool.cc'
                    >hello@wusool.cc</a
                  >
                </h3>`
                )}})])])]),_c('div',{staticClass:"right wow fadeInRight",attrs:{"data-wow-duration":"1s"}},[_c('div',{staticClass:"fields"},[_c('form',{ref:"form",staticClass:"contact_form",attrs:{"id":"contact_form"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"returnmessage",style:({
                  display: _vm.success ? 'block' : 'none',
                }),attrs:{"data-success":"Your message has been received, We will contact you soon."}},[_c('span',{staticClass:"contact_success"},[_vm._v(" Your message has been received, We will contact you soon. ")])]),_c('div',{staticClass:"empty_notice",style:({ display: _vm.error ? 'block' : 'none' })},[_c('span',[_vm._v("Please Fill Required Fields")])]),_c('div',{staticClass:"input_list"},[_c('ul',[_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_name),expression:"user_name"}],attrs:{"id":"name","type":"text","name":"user_name","placeholder":"Your Name"},domProps:{"value":(_vm.user_name)},on:{"input":function($event){if($event.target.composing)return;_vm.user_name=$event.target.value}}})]),_c('li',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user_email),expression:"user_email"}],attrs:{"id":"email","type":"text","name":"user_email","placeholder":"Your Email"},domProps:{"value":(_vm.user_email)},on:{"input":function($event){if($event.target.composing)return;_vm.user_email=$event.target.value}}})])])]),_c('div',{staticClass:"message_area"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],attrs:{"id":"message","name":"message","placeholder":"Your message here"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing)return;_vm.message=$event.target.value}}})]),_c('div',{staticClass:"aali_tm_button"},[_c('a',{attrs:{"id":"send_message","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('span',{domProps:{"innerHTML":_vm._s('Submit Message' + _vm.msgSent)}})])])])])])])]),_c('span',{staticClass:"square moving_effect",attrs:{"data-direction":"y","data-reverse":"yes"}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aali_tm_main_title",attrs:{"data-text-align":"center","data-color":"dark"}},[_c('span',[_vm._v("Get in Touch")]),_c('h3',[_vm._v("Contact us")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('p',[_vm._v(" Please fill out the form on this section to contact with us. Or call between 10:00 a.m. and 6:00 p.m., Sunday through Thursday ")])])
}]

export { render, staticRenderFns }